import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
import "../assets/css/main.css";
import { Link } from "gatsby";

import { Router, Redirect } from "@reach/router"

// export default () => <></>

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (


    <Layout seo={data.strapiHomepage.seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-small uk-text-center">
          <span uk-spinner="ratio: 4.5"></span>
          {/* <h1>{data.strapiHomepage.hero.title}</h1> */}
          {/* <ArticlesComponent articles={data.allStrapiArticle.edges} /> */}
        </div>
      </div>
    </Layout >
  );
};


const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

//     allStrapiArticle(filter: { status: { eq: "published" } }) {
//       edges {
//         node {
//           strapiId
//           slug
//           title
//           publishedAt
//           description
//           sentiment
//           subjectivity
//           percentage
//           category {
//             name
//           }
//           authors {
//             name
//             picture {
//               childImageSharp {
//                 fixed(width: 30, height: 30) {
//                   src
//                 }
//               }
//             }
//           }
//         }
//       }
//     }


// image {
//   childImageSharp {
//     fixed(width: 800, height: 500) {
//       src
//     }
//   }
// }

export default IndexPage;
